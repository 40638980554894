import account from './account'
import alarm from './alarm'
import demo from './demo'
import equipment from './equipment'
import gatewayMsg from './gatewayMsg'
import gateway from './gateway'
import log from './log'
import login from './login'
import monitor from './monitor'
import organization from './organization'
import platform from './platform'
import role from './role'
import sms from './sms'
import system from './system'
import tenant from './tenant'
import menu from './menu'
import patrol from './patrol'
import spare from './spare'
import stock from './stock'
import alarmData from './alarmData'
import home from './home'
import run from './run'
import electric from './electric'
import resistance from './resistance'
import file from './file'
import equipmentData from './equipmentData'
import updateLog from './updateLog'
import mete from './mete'
import alarmtrend from './alarmtrend'
import sitePositioning from './sitePositioning'

const configMap = {
  demo,
  platform,
  monitor,
  system,
  login,
  role,
  gateway,
  account,
  log,
  organization,
  sms,
  tenant,
  alarm,
  equipment,
  gatewayMsg,
  menu,
  patrol,
  spare,
  stock,
  alarmData,
  home,
  run,
  electric,
  file,
  equipmentData,
  resistance,
  updateLog,
  mete,
  alarmtrend,
  sitePositioning
}

export default configMap
