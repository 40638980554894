export default {
  list: {
    config: {
      url: '/role/page',
      method: 'post'
    }
  },
  add: {
    config: {
      url: '/sitePositioning',
      method: 'post'
    }
  },
  update: {
    config: {
      url: '/sitePositioning',
      method: 'put'
    }
  },
  delete: {
    config: {
      url: '/sitePositioning',
      method: 'delete'
    }
  },
  query: {
    config: {
      url: '/sitePositioning/query',
      method: 'post',
      data: {}
    }
  },
  getBySiteId: {
    config: {
      url: '/sitePositioning/getByStieId/:siteId',
      method: 'get',
      data: {}
    }
  }
}
