const home = {
  leftTopCounter: {
    config: {
      url: "/equipment/countOnlineEquipment",
      method: "POST",
    },
  },
  leftTopList: {
    config: {
      url: "/equipment/pageEquipment",
      method: "POST",
    },
  },
  right: {
    config: {
      url: "/alarmData/page",
      method: "POST",
    },
  },
  wind: {
    config: {
      url: "/micWindData/pageData",
      method: "POST",
    },
  },
  atmos: {
    config: {
      url: "/micAtmosData/pageData",
      method: "POST",
    },
  },
  map: {
    config: {
      url: "/equipment/page",
      method: "POST",
    },
  },
  detailChart: {
    config: { url: "/equipment/equipmentData", method: "POST" },
  },
  uuid: {
    config: {
      url: "/web/uuid",
      method: "GET",
    },
  },
  close: {
    config: {
      url: "/web/close",
      method: "GET",
    },
  },
  changeEquipmentCache: {
    config: {
      method: "GET",
      url: "/web/changeEquipmentCache",
    },
  },
  clear: {
    config: {
      method: "POST",
      url: "/alarmData/clearDataByIds",
    },
  },
  clearAll: {
    config: {
      method: "POST",
      url: "/alarmData/clearAllData",
    },
  },
};

export default home;
