// 备品备件
export default {
  list: {
    config: {
      url: "/lightSpareParts/page",
      method: "post"
    }
  },
  add: {
    config: {
      url: "/lightSpareParts",
      method: "post"
    }
  },
  update: {
    config: {
      url: "/lightSpareParts",
      method: "put"
    }
  },
  delete: {
    config: {
      url: "/lightSpareParts",
      method: "delete"
    }
  }
};
