const equipment = {
  list: {
    config: {
      url: '/equipment/page',
      method: 'post'
    }
  },
  queryOne: {
    config: {
      url: '/equipment',
      method: 'get'
    }
  },
  add: {
    config: {
      url: '/equipment',
      method: 'post'
    }
  },
  update: {
    config: {
      url: '/equipment',
      method: 'put'
    }
  },
  delete: {
    config: {
      url: '/equipment',
      method: 'delete'
    }
  },

  getParams: {
    config: {
      url: '/equipment/getEquipmentParams',
      method: 'post'
    }
  },
  control: {
    config: {
      url: '/equipment/controlEquipment',
      method: 'post'
    }
  },
  updateParams: {
    config: {
      url: '/equipment/modifyEquipmentParams',
      method: 'post'
    }
  },
  updateAlarmFlag: {
    config: {
      url: '/equipment/updateAlarmFlag',
      method: 'post'
    }
  },
  updateFirmPatch: {
    config: {
      url: '/equipment/batchUpdateEquipment',
      method: 'post'
    }
  },
  listByIds: {
    config: {
      url: '/equipment/listByIds',
      method: 'post'
    }
  }
}

export default equipment
