const org = {
  list: {
    config: {
      url: '/tenant/page',
      method: 'post',
    },
  },
  add: {
    config: {
      url: '/tenant',
      method: 'post',
    },
  },
  update: {
    config: {
      url: '/tenant',
      method: 'put',
    },
  },
  delete: {
    config: {
      url: '/tenant',
      method: 'delete',
    },
  },
  export: {
    config: {
      url: '/tenant/export',
      method: 'post',
      responseType: 'blob'
    },
  },
};

export default org;
