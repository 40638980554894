import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import http from "@/http";

const SPACE_NAME = "app";

export interface MenuItem {
  name: string;
  children?: MenuItem[];
  path?: string;
}

interface InitialState {
  userInfo: {
    [key: string]: any;
  };
  menus: MenuItem[];
  permission: {
    [key: string]: boolean;
  };
  lang: string;
}

const initialState: InitialState = {
  userInfo: {},
  menus: [],
  permission: {},
  lang: "zh-CN"
};

export const fetchMenu: any = createAsyncThunk(
  `${SPACE_NAME}/fetchMenu`,
  async userId => {
    const menus = await http("menu", "router", {
      params: {
        userId
      }
    });
    return formatMenu(menus);
  }
);

const slice = createSlice({
  name: SPACE_NAME,
  initialState,
  reducers: {
    setUserInfo(state, action) {
      state.userInfo = action.payload || {};
    },
    setLang(state, action) {
      state.lang = action.payload || "zh-CN";
    }
  },
  extraReducers: {
    [fetchMenu.fulfilled](state, action) {
      const { menus, permission } = action.payload;
      state.menus = menus;
      state.permission = permission;
    }
  }
});

export const { actions, reducer } = slice;

export default reducer;

const RightPathMap = {
  "/address-1100": "/page/screen",
  "/address-1200": "/page/home",
  "/address-1300": "/page/business",
  "/address-1400": "/page/statistic",
  "/address-1500": "/page/system",
  "/address-1520": "/page/system/role1"
};

function getRightPath(path) {
  return RightPathMap[path] || path;
}

function formatMenu(list: any) {
  const permission = {};

  function loop(data: any[]) {

    data.forEach(item => {
      const { path, resources = [], children = [] } = item;

      item.path = getRightPath(path);
      resources.forEach(v => {
        if (v.code) {
          permission[v.code] = true;
        }
      });
      loop(children);
    });
  }

  loop(list);

  // list = list.filter((item) => item.path != "/page/home")
  localStorage.setItem("ROLE_LIST", JSON.stringify(list));
  localStorage.setItem("SOURCE_LIST", JSON.stringify(permission));
  return {
    menus: list,
    permission
  };
}
