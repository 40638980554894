import { createHashHistory } from 'history';

const history = createHashHistory();

let stackList: string[] = [];

history.listen(route=>{
    let { pathname } = route;
    stackList.push(pathname);
});


export function gotoPage(newPath:string, params?: any){
    let oldPath = history.location.pathname;
    
    if(newPath === oldPath){
        return;
    }

    // history.push({
    //     pathname: newPath,
    //     params
    // });
    history.push(newPath);
}

export function goBack(){
    if(stackList.length > 0){
        let pathname = stackList[stackList.length - 1];
        stackList.pop();
        history.replace(pathname);
    }
}

export default history;