const gateway = {
  list: {
    config: {
      url: '/gateway/page',
      method: 'post',
    },
  },
  add: {
    config: {
      url: '/gateway',
      method: 'post',
    },
  },
  update: {
    config: {
      url: '/gateway',
      method: 'put',
    },
  },
  delete: {
    config: {
      url: '/gateway',
      method: 'delete',
    },
  },
  query: {
    config: {
      url: '/gateway/query',
      method: 'post',
    },
  },
  control: {
    config: {
      url: '/equipment/controlGateway',
      method: 'post',
    },
  },
};

export default gateway;
