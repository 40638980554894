const equipmentData = {
  list: {
    config: {
      url: "/equipmentData/page",
      method: "post"
    }
  },
  export: {
    config: {
      url: "/equipmentData/export",
      method: "post",
      responseType: 'blob'
    }
  }
};

export default equipmentData;
