const log = {
  list: {
    config: {
      url: "/commLog/page",
      method: "post",
    },
  },
  // add: {
  //   config: {
  //     url: "/optLog",
  //     method: "post",
  //   },
  // },
  // update: {
  //   config: {
  //     url: "/optLog",
  //     method: "put",
  //   },
  // },
  // delete: {
  //   config: {
  //     url: "optLog",
  //     method: "delete",
  //   },
  // },
  // export: {
  //   config: {
  //     url: "/optLog/export",
  //     method: "post",
  //     responseType: "blob",
  //   },
  // },
};

export default log;
