const patrol = {
  list: {
    config: {
      url: "/lightDevopsinSpection/page",
      method: "post",
    },
  },
  add: {
    config: {
      url: "/lightDevopsinSpection/add",
      method: "post",
    },
  },
  update: {
    config: {
      url: "lightDevopsinSpection/mod",
      method: "post",
    },
  },
  delete: {
    config: {
      url: "/lightDevopsinSpection",
      method: "delete",
    },
  },
  query: {
    config: {
      url: "/lightDevopsinSpection/query",
      method: "post",
    },
  },
};

export default patrol;
