const file = {
  list: {
    config: {
      url: '/file/page',
      method: 'post'
    }
  },
  addfile: {
    config: {
      url: '/file/anyone/upload',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  },
  addrecord: {
    config: {
      url: '/file/update',
      method: 'post'
    }
  },
  update: {
    config: {
      url: '/file/anyone/upload',
      method: 'post',
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  },
  delete: {
    config: {
      url: '/file',
      method: 'delete'
    }
  },
  findOneById: {
    config: {
      url: '/file/:id',
      method: 'get'
    }
  },
  findUrlById: {
    config: {
      url: '/file/anyone/findUrlById',
      method: 'post'
    }
  }
}

export default file
