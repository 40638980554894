const account = {
  list: {
    config: {
      url: '/user/page',
      method: 'post',
    },
  },
  add: {
    config: {
      url: '/user',
      method: 'post',
    },
  },
  update: {
    config: {
      url: '/user',
      method: 'put',
    },
  },
  delete: {
    config: {
      url: '/user',
      method: 'delete',
    },
  },
  export: {
    config: {
      url: '/user/export',
      method: 'post',
      responseType: 'blob'
    },
  },
  onOff: {
    config: {
      url: '/user/onOffBatch',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  },
  reset: {
    config: {
      url: '/user/reset',
      method: 'post',
    },
  },
  resetBatch: {
    config: {
      url: '/user/resetBatch',
      method: 'post',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  },
  resetPassword: {
    config: {
      url: '/user/password',
      method: 'put'
    },
  }
};

export default account;
