import React, { useEffect, useState } from "react";
import Routers, { RouteList } from "@/router";
import { Provider } from "react-redux";
import { ConfigProvider } from "antd";
import zhCN from "antd/lib/locale-provider/zh_CN";
import enUS from "antd/lib/locale-provider/en_US";
import { Router } from "react-router-dom";
import store from "./store";
import "antd/dist/antd.less";
import "@/style/index.less";
import history from "@/router/history";
import { useSize } from "@/hook";
// import "@/mock";
import emitLocal from "@/utils/emit"

import intl from "react-intl-universal";

//  国际化语言包，这里是自建的部分
const locales: any = {
  "en-US": require("./locales/en-US.json"),
  "zh-CN": require("./locales/zh-CN.json")
};

const App: React.FC = props => {
  // const size = useSize();
  const [initDone, setInitDone] = useState(false);
  const [localLan, setLocalLan] = useState("zh-CN");

  function loadLocales() {

    emitLocal.on("change_local", (lang) => {
      setLocalLan(lang);
      localStorage.setItem("local_lang", lang);
      window.location.reload();
    });

    // react-intl-universal 是单例模式, 只应该实例化一次
    intl.init({
      currentLocale: localStorage.getItem("local_lang") || localLan, // TODO: determine locale here
      locales,
    }).then(() => {
      setInitDone(true);
    });
  }

  loadLocales();

  // function setHtmlFontSize() {
  //   let bHeight = size.bHeight;
  //   let fontsize = ((100 * bHeight) / 960).toFixed(2) + "px";
  //   (document as any).querySelector('html').style.fontSize = fontsize;
  // }

  // useEffect(() => {
  //   size && setHtmlFontSize();
  // }, [size]);

  return (
    <Provider store={store}>
      {/* <ConfigProvider locale={localLan == "zh-CN" ? zhCN : enUS}> */}
      <Router history={history}>
        <Routers list={RouteList} />
      </Router>
      {/* </ConfigProvider> */}
    </Provider>
  );
};

export default App;
