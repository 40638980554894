const build = (url: string, type: string) => {
  return {
    url,
    method: type,
  };
};

const get = (url: string) => build(url, "GET");
const post = (url: string) => build(url, "POST");

const run = {
  gjlxpm: {
    config: get("/alarmType/alarmStatistics"),
  },
  gjlxzb: {
    config: get("/alarmType/alarmStatisticsPercent"),
  },
  sblxyl: {
    config: get("/alarmType/equipmentList"),
  },
  trend: {
    // config: get("/alarmType/alarmTrend&typ"),
    config: {
      url: "/alarmType/alarmTrend",
      method: "post",
    },
  },
  yxsjtj: {
    config: get("/alarmType/operationalStatistics"),
  },
};

export default run;
