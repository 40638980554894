import React, { Suspense } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import history from './history'
import intl from 'react-intl-universal'
import defaultIcon from '@/assets/images/menu/default.png'
import { LoadingOutlined } from '@ant-design/icons'

// "menu-home":"home",
// "menu-business":"bussiness",
// "menu-system":"system",
// "menu-data":"query and statistics",
// "menu-screen":"screen",
// "menu-login":"login",

const RouteList: any[] = [
  {
    path: '/page',
    redirect: true,
    component: React.lazy(() => import('@/views/layout')),
    meta: { name: '' },
    routes: [
      {
        path: '/page',
        component: React.lazy(() => import('@/views/home/index')),
        // exact: true,
        meta: { name: intl.get('menu-home') }
      }
      // {
      //   path: "/page/business",
      //   component: React.lazy(() => import("@/views/business")),
      //   meta: { name: intl.get("menu-business") }
      // },
      // {
      //   path: "/page/statistic",
      //   component: React.lazy(() => import("@/views/statistic")),
      //   meta: { name: intl.get("menu-data") }
      // },
      // {
      //   path: "/page/system",
      //   component: React.lazy(() => import("@/views/system")),
      //   meta: { name: intl.get("menu-system") }
      // }
    ]
  },
  {
    meta: { name: intl.get('menu-screen') },
    exact: true,
    path: '/screen',
    component: React.lazy(() => import('@/views/screen/index'))
  },
  {
    meta: { name: intl.get('menu-loop-view') },
    exact: true,
    path: '/loop-view',
    component: React.lazy(() => import('@/views/loop-view/index'))
  },
  {
    path: '/login',
    component: React.lazy(() => import('@/views/login')),
    exact: true,
    meta: { name: intl.get('menu-login') }
  }
]

const Routers: React.FC<{
  list: any[]
}> = (props) => {
  let { list = [] } = props
  let redirectRouter = RouteList.filter((item) => item.redirect === true)[0]

  if (redirectRouter && redirectRouter.routes && redirectRouter.routes.length > 0) {
    redirectRouter = redirectRouter.routes[0]
  }

  return (
    <Suspense
      fallback={
        <div className="page-loading">
          <LoadingOutlined />
        </div>
      }
    >
      <Switch>
        {list.map((item, index) => {
          let { path, exact, component, routes } = item
          let Com = component
          return (
            <Route key={index} exact={exact} path={path} component={component}>
              <Com history={history} routes={routes} />
            </Route>
          )
        })}
        {redirectRouter ? <Redirect to={redirectRouter.path} /> : null}
      </Switch>
    </Suspense>
  )
}

export default Routers

export { RouteList }
