const electric = {
  list: {
    config: {
      url: '/lightningDetectorData/page',
      method: 'POST',
    },
  },
  query: {
    config: {
      url: '/lightningDetectorData/page',
      method: 'POST',
    },
  },
  export: {
    config: {
      url: '/lightningDetectorData/export',
      method: 'POST',
      responseType: 'blob',
    },
  },
};

export default electric;
