import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ConfigProvider } from 'antd'
import zhCN from 'antd/lib/locale-provider/zh_CN'
import enUS from 'antd/lib/locale-provider/en_US'
import 'react-resizable/css/styles.css'

const getLan = () => {
  let lan = localStorage.getItem('local_lang')
  if (!lan) {
    return zhCN
  }
  return lan == 'zh-CN' ? zhCN : enUS
}

ReactDOM.render(
  <ConfigProvider locale={getLan()}>
    <App />
  </ConfigProvider>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
