const alarmtrend = {
  list: {
    config: {
      url: "/terndAlarmRules/page",
      method: "post",
    },
  },
  update: {
    config: {
      url: "/terndAlarmRules",
      method: "put",
    },
  },
};

export default alarmtrend;
