const updateLog = {
  list: {
    config: {
      url: '/equipmentUpdateLog/page',
      method: 'post',
    },
  },
};

export default updateLog;
