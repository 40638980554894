import request from "@/utils/request";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import $ from "jquery";
import configMap from "./config";

type ConfigType = typeof configMap;

type GroupType = keyof ConfigType;

type UelType<T extends GroupType> = keyof ConfigType[T];

type ItemType<
  T extends GroupType,
  K extends UelType<T>
  > = typeof configMap[T][K];

function getConfigItem<T extends GroupType, K extends UelType<T>>(
  group: T,
  uelKey: K
): ItemType<T, K> {
  return configMap[group][uelKey];
}

function http<T extends GroupType, K extends UelType<T>>(
  group: T,
  uelKey: K,
  userConfig?: AxiosRequestConfig
) {

  let configItem = getConfigItem<T, K>(group, uelKey);

  let { config, handler } = configItem as any;

  config = $.extend(true, {}, config, userConfig || {});
  return request.request(config).then((rep): any => {
    return handler ? handler(rep) : rep;
  });
}

export default http;

export const createHttpMode = <T extends GroupType>(group: T) => {
  let temp: any = {};
  return new Proxy<
    Record<
      UelType<T>,
      <R = any>(
        data?: any,
        config?: AxiosRequestConfig
      ) => Promise<AxiosResponse<R>["data"]>
    >
  >(temp, {
    get(target, key: any) {
      return (data, config) => {
        return http(
          group,
          key,
          Object.assign({}, config || {}, data ? { data } : {})
        );
      };
    }
  });
};
